.index__hero {
	flex-direction: column;
	background-color: $grey-dark;

	@include md {
		flex-direction: row;
		justify-content: space-between;
	}

	&__title {
		color: $white;
		margin-bottom: em-calc($gutter * 2, 40px);
		
		font-size: em-calc(40px);
		line-height: em-calc(46px, 40px);

		@include lg {
			font-size: em-calc($size-xl);
			line-height: em-calc(64px, $size-xl);	
		}
	}

	&__text {
		color: $white;
		font-size: em-calc(14px);
		line-height: em-calc(20px, 14px);
		font-weight: $font-semibold;
		margin-bottom: em-calc($gutter * 2, 14px);

		@include md {
			font-size: em-calc(18px);
			line-height: em-calc(24px, 18px);
		}
	}

	&__content {
		padding: em-calc($menuMobile + ($gutter * 3)) em-calc($gutter + 5) em-calc($gutter + 5);
		@include md {
			width: 40vw;
			padding: em-calc($menuDesktop + ($gutter * 4)) em-calc(40px) em-calc(40px);
		}
	}

	&__image {
		background-image: url('../images/heroes/arbeigo-hero2.jpg');		
		background-repeat: no-repeat;
    	background-size: cover;
    	background-position: center bottom;
    	padding-bottom: 66.213389%;

    	@include md {
			width: 60vw;
			padding-bottom: 0;
    	}
	}
}