/*
* SAMARBEJDSPARTNERE
*/
.partners {
	background-color: $yellow;
	padding-top: em-calc($gutter * 2);
	padding-bottom: em-calc($gutter * 2);
	position: relative;
}

.partners__title {
	font-size: em-calc(20px);
	line-height: em-calc(26px, 20px);
	font-weight: $font-semibold;
	font-style: italic;
	color: $white;
	text-align: center;
	padding-bottom: em-calc($gutter * 2, 20px);
}

// Desktop swiper
.partners__swiper {
    width: 100%;
    height: 200px;
    display: none;
    position: static;

    @include md {
    	display: block;
    }
}

.partners__swiper__slide {  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.partners__swiper_next {
	background-image: url('../images/main/swiper-right.png');
	// right: em-calc(25px);
	@include xl {
		right: em-calc(50px);
	}
}

.partners__swiper_prev {
	background-image: url('../images/main/swiper-left.png');
	// left: em-calc(25px);
	@include xl {
		left: em-calc(50px);
	}
}

.partners__swiper_next,
.partners__swiper_prev {
	display: none;
	width: 14px;
	height: 26px;
	background-size: 14px 26px;
	top: 50%;
	margin-top: 0;
	@include lg {
		display: block;
	}
}

// mobile
.partners__sm {
	display: flex;
    justify-content: space-around;
    align-items: baseline;
    flex-wrap: wrap;
    align-content: space-around;

    @include md {
    	display: none;
    }

    &__image {
    	flex-grow: 0;
    	padding: em-calc($gutter);
    }
}