.support {
	background-color: $grey-alt;
	margin-left: -15px;
    margin-right: -15px;
	flex-flow: row wrap;
	padding: 0;

    @include md {
    	margin-left: 0;
    	margin-right: 0;
    }
}

.support__img {
	line-height: 0;
	display: none;
	flex: 1 100%;
	order: 1;

	@include md {
		display: block;
		flex: 1 50%;
	}

	img {
		width: 100%;
	}
}

.support__content {
	flex: 1 100%;
	order: 2;
	padding: em-calc($gutter);
	flex-direction: column;
	justify-content: flex-start;


	@include md {
		flex: 1 50%;
		padding: em-calc($gutter * 2);
	}
}

.support__title {
	color: $white;

	@include md {
		color: $heading-color;
	}
}

.support__info {
    list-style-type: none;
    font-size: em-calc(14px);
    line-height: em-calc(20px, 14px);
    padding: em-calc($gutter * 3) 0;

    li {
    	padding: em-calc(6px) 0;
    }
}

.support__contact {
	display: flex;
    justify-content: flex-start;
    align-items: center;
}

.support__contact__tel {
	padding-left: em-calc($gutter * 2);
	font-weight: $font-semibold;
}

.support__bottom {
	flex: 1 100%;
	order: 4;
	align-items: center;
    justify-content: space-between;
	background-color: $grey-dark;
	padding: em-calc($gutter);

	@include md {
		padding: em-calc($gutter * 2);
	}
}

.support__bottom__title {
	color: $white;
}