/*
* OVERBLIK
*/
.section--overview {
	position: relative;
}

.overview {
	flex-direction: column;
	padding-bottom: em-calc(80px);

	@include md {
		flex-direction: row;
		padding-bottom: 0;
	}
}

.overview__title {
    @include md {
    	padding-left: 50%;
    	margin-bottom: em-calc($gutter * 2, $size-l);
    }
}

.overview__image {
	@include md {
		width: 50%;
	}

	img {
		display: block;
		max-width: 80%;
    	margin-left: auto;
    	margin-right: auto;
	}
}

.overview__reasons {
	@include md {
		width: 50%;
	}
}

.overview__list {
	// padding-top: em-calc(($gutter * 2) + 5, $size-xs);
	// padding-bottom: em-calc(($gutter * 2) + 5, $size-xs);
	
	@include md {
		padding-left: em-calc($gutter * 2);
	}
}

.overview__item {
	margin-bottom: em-calc($gutter * 2);
	flex-direction: column;

	@include md {
		flex-direction: row;
		margin-bottom: em-calc(20px);
	}

	&__title {
    	padding-bottom: em-calc(10px);

    	@include md {
    		padding-top: em-calc(10px);
    	}
	}

	&__icon svg {
	    height: em-calc(40px);
	    fill: $yellow;
	}

	&__content {
		@include md {
			padding-left: em-calc($gutter * 3);
		}
	}

	&__text p {
		font-size: em-calc(14px);
		line-height: em-calc(20px, 14px);
		margin-bottom: 0;
	}
}

.overview__button {
	margin-top: em-calc($gutter * 2, 13px);

	@include md {
		margin-left: em-calc(110px, 13px);
	}
}

.overview__download {
	position: absolute;
	bottom: em-calc($gutter * 2);
	left: 0;
	height: em-calc(50px);

	@include md {
		bottom: em-calc($gutter * 4);
	}

	img {
		width: em-calc(115px);
		float: left;
		
		@include md {
			height: 100%;
			width: auto;
		}

		&:first-child {
			margin-right: em-calc(10px);
		}
	}
}