/*
 * DEFAULTS
*/

$em-base: 16px !default;

/*
 * FUNCTIONS
*/

// Strip unit of measure
@function strip-unit ($num) 
{
    @return $num / ($num * 0 + 1);
}

// Convert to EM
@function convert-to-em ($value, $base-value: $em-base)
{
    $value: strip-unit($value) / strip-unit($base-value) * 1em;
    @if ($value == 0em) { $value: 0; } // Turn 0em into 0
    @return $value;
}

// Calculate EMs
@function em-calc ($values, $base-value: $em-base)
{
    $max: length($values);

    @if $max == 1 { @return convert-to-em(nth($values, 1), $base-value); }

    $emValues: ();
    @for $i from 1 through $max {
        $emValues: append($emValues, convert-to-em(nth($values, $i), $base-value));
    }
    @return $emValues;
}

/*
 * MIXINS
*/

// Clearfix
@mixin clearfix 
{
    &:after
    {
      content: "";
      display: table;
      clear: both;
    }
}

// Quick media queries
@mixin after ($width)
{
    @media (min-width: $width) { @content; }
}

@mixin before ($width)
{
    @media (max-width: $width) { @content; }
}

@mixin within ($min, $max)
{
    @media (min-width: $min) and (max-width: $max) { @content; }
}

@mixin retina 
{
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { @content; }
}

/*
 * RULES
*/

// Box-sizing
html 
{
    box-sizing: border-box;
}

*, *:before, *:after 
{
    box-sizing: inherit;
}

// Clearfix
.clearfix 
{
    @include clearfix;
}

// Helpers
.hide-mobile {
    @include before(768px) {
        display: none;
    }
}

.hide-desktop {
    @include after(768px) {
        display: none;
    }
}