.functions {
	background-image: url('../images/index/functions-bg.jpg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	display: none;

	@include lg {
		display: block;
	}
}

.functions__top {
	padding-top: em-calc($gutter * 3);
	padding-bottom: em-calc($gutter * 3);
}

.functions__title {
	color: $white;
	margin-right: em-calc($gutter * 2, $size-l);
}

.functions__shapes {
	padding-top: em-calc($gutter * 4);
	padding-bottom: em-calc($gutter * 4);

	justify-content: space-around;
	text-align: center;

	@include lg {
		height: em-calc(300px, $size-xs);
	}

	@include xl {
		height: em-calc(350px);
	}
}

.shape {
	width: 25%;
	display: flex;
	align-items: center;
	position: relative;
}

.shape:before {
    border-top: 2px solid $white;
    content:"";
    margin: 0 auto;
    position: absolute;
    top: 50%; 
    left: 0; 
    right: 0; 
    bottom: 0;
    width: 100%;
}

.shape__icon {
	width: 100%;
	display: inline-block;
	position: absolute;
	top: em-calc(30px);
	left: 0;
	z-index: 2;

	svg {
		fill: $yellow;
		height: em-calc(35px);
	}
}

.shape__content {
	text-align: center;
	position: relative;
    z-index: 2;
    width: 100%;
    padding-left: em-calc($gutter);
    padding-right: em-calc($gutter);
}

.shape__text {
	font-weight: $font-semibold;
}

.shape__text__hover {
	color: $white;
	text-decoration: underline;
	font-weight: $font-semibold;
}

.shape__image {
	position: absolute;
	top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    svg {
    	height: 100%;
    	width: auto;
    }
}