/*
* OVERBLIK
*/
.section--contact {
	position: relative;
}

.contact {
	flex-direction: column;

	@include md {
		flex-direction: row;
		justify-content: center;
	}

	&__title {
		margin-bottom: em-calc($gutter);
	}

	&__form {
		max-width: em-calc(380px);

		@include md {
			max-width: 100%;
		}
	}
}

.contact__item {
	padding: em-calc($gutter * 4) 0;
	
	@include md {
		padding-right: em-calc($gutter * 2);
		padding-left: em-calc($gutter * 4);
		flex-grow: 1;
		max-width: em-calc(430px);
	}

	&--border {
		border-bottom: 1px solid $border-color;
		padding-top: em-calc($gutter);

		@include md {
			padding-left: em-calc($gutter * 2);
			padding-right: em-calc($gutter * 4);
			border-right: 1px solid $border-color;
			border-bottom: 0;
			padding-top: em-calc($gutter * 4);
		}
	}
}

.contact__form input[type="text"] {
	border: 1px solid $yellow;
	float: left;
    width: 65%;

    @include md {
    	margin-right: em-calc(6px, 14px);
    }
}

.contact__form input[type="submit"] {
	float: right;
	width: 30%;
}