/*
* PRISER
*/
.section--prices {
	// padding-bottom: em-calc($gutter * 2);
	// padding-top: em-calc($gutter * 2);
}

.prices {
	flex-direction: column;
	padding-bottom: em-calc($gutter * 2);

	@include md {
		flex-direction: row;
		padding-bottom: em-calc($gutter * 3);
		padding-top: em-calc($gutter);
	}
}

.prices__title {
    margin-bottom: em-calc($gutter * 2, $size-m);
}

.prices__item {
	margin-bottom: em-calc($gutter * 2);

	@include md {
		margin-bottom: 0;
		padding-right: em-calc($gutter * 4);
    	padding-left: em-calc($gutter * 4);
    	border-right: 1px solid $border-color;

    	&:last-child {
    		border-right: 0;
    		padding-right: 0;
    	}

    	&:first-child {
    		padding-left: 0;
    	}
	}

	&__title {
    	padding-bottom: em-calc(10px);
	}

	&__price {
	    color: $yellow;
	    font-weight: $font-semibold;
	}

	&__text p {
		font-size: em-calc(14px);
		line-height: em-calc(20px, 14px);
	}

	&__img {
		text-align: left;

		img {
			display: inline-block;
		}
	}
}

/*
* BEREGNER
*/
.price_calculator {
	background-color: $grey-alt;
	color: $white;
}

.price_calculator__intro {
	padding: em-calc($gutter * 3 $gutter * 3 $gutter);
	align-items: center;
	flex-direction: column;

	@include md {
		flex-direction: row;
	}
}

.price_calculator__title {
	display: inline-block;
	margin-bottom: em-calc($gutter, $size-m);

	@include md {
		margin-right: em-calc($gutter * 2, $size-m);
		margin-bottom: 0;
	}
}

.price_calculator__inputs {
	padding: em-calc($gutter * 2 $gutter * 3 $gutter * 3);
	justify-content: space-between;
	flex-direction: column;

	@include md {
		flex-direction: row;
		align-items: flex-end;
	}
}

.price_calculator__input {
	flex-grow: 1;
	margin-bottom: em-calc($gutter * 2);
	
	@include md {
		padding: $gutter * 2;
		margin-bottom: 0;

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
		}
	}

	&__title {
		padding-bottom: em-calc(10px, $size-xs);
	}

	&_number {
    	width: 100%;
    	font-size: em-calc(16px);
    	color: $yellow;
    	padding: em-calc($gutter);

		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		  color: $yellow;
		}

		&::-moz-placeholder { /* Firefox 19+ */
		  color: $yellow;
		}

		&:-ms-input-placeholder { /* IE 10+ */
		  color: $yellow;
		}

		&:-moz-placeholder { /* Firefox 18- */
		  color: $yellow;
		}
	}
}

.price_calculator__result {
	padding: em-calc($gutter * 2 $gutter * 3);
	background-color: $grey-dark;
}

.price_calculator__value {
	color: $yellow;
	text-decoration: underline;
}