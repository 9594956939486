.footer {
	background-color: $grey-dark;
	color: $white;
	padding-top: em-calc($gutter * 2);
	padding-bottom: em-calc($gutter * 4); 
	padding-left: em-calc($gutter);
	padding-right: em-calc($gutter);
	position: relative;

	@include md {
		padding-bottom: em-calc($gutter * 2); 
	}
}

.footer__main {
	margin-bottom: em-calc($gutter + 5);
	overflow: hidden;
}

.footer__list {
	font-size: em-calc(14px);
	line-height: em-calc(20px, 14px);

	@include md {
		float: left;
		line-height: em-calc(30px, 14px);
	}

	&__item {
		padding-bottom: em-calc($gutter);

		@include md {
			float: left;
			padding-bottom: 0;
			padding-right: em-calc($gutter * 2);
		}
	}
}

.footer__facebook {
	width: em-calc(30px);
	height: em-calc(30px);
	line-height: em-calc(30px);
	border-radius: 100%;
	background-color: $yellow;
	color: $font-color;
	text-align: center;

	position: absolute;
    left: 15px;
    bottom: 30px;

	@include md {
		float: left;
		position: static;

		&:hover {
			background-color: $yellow-alt;
		}
	}
}

.footer__copyright {
	display: flex;
	position: absolute;
    right: 15px;
    bottom: 30px;

    @include md {
    	position: static;
    }
	
	span {
		font-size: em-calc(14px);
		line-height: em-calc(28px, 14px);
		display: inline-block;
    	margin-right: em-calc(10px);
	}

	img {
		height: em-calc(28px);
	}
}