/*
 * DEFAULTS
*/

$vw-base: 1440px !default;
$vh-base: 960px !default;

// Media query breakpoints
$screen-xs-min: 0px !default;
$screen-min: 320px !default;
$screen-sm-min: 450px !default;
$screen-md-min: 770px !default;
$screen-lg-min: 1200px !default;
$screen-xl-min: 1500px !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
$screen-lg-max: ($screen-xl-min - 1) !default;

// Container sizes
$container-sm: $screen-sm-min !default;
$container-md: $screen-md-min !default;
$container-lg: $screen-lg-min !default;

$grid-gutter-width:         30px !default;
$grid-gutter-width-half:    $grid-gutter-width / 2;

/*
 * FUNCTIONS
*/

// Convert to VW
@function convert-to-vw ($value, $base-value: $vw-base)
{
    $value: ((strip-unit($value) / strip-unit($base-value)) * 100) * 1vw;
    @if ($value == 0vw) { $value: 0; } // Turn 0vw into 0
    @return $value;
}

// Calculate VWs
@function vw-calc ($values, $base-value: $vw-base)
{
    $max: length($values);

    @if $max == 1 { @return convert-to-vw(nth($values, 1), $base-value); }

    $vwValues: ();
    @for $i from 1 through $max {
        $vwValues: append($vwValues, convert-to-vw(nth($values, $i), $base-value));
    }
    @return $vwValues;
}

// Convert to VH
@function convert-to-vh ($value, $base-value: $vh-base)
{
    $value: ((strip-unit($value) / strip-unit($base-value)) * 100) * 1vh;
    @if ($value == 0vh) { $value: 0; } // Turn 0vh into 0
    @return $value;
}

// Calculate VHs
@function vh-calc ($values, $base-value: $vh-base)
{
    $max: length($values);

    @if $max == 1 { @return convert-to-vh(nth($values, 1), $base-value); }

    $vhValues: ();
    @for $i from 1 through $max {
        $vhValues: append($vhValues, convert-to-vh(nth($values, $i), $base-value));
    }
    @return $vhValues;
}

/*
 * MIXINS
*/
@mixin xs {
    @content;
}

@mixin sm {
    // @include after(em-calc(320px)) {
    //     @content;
    // }

    @include after(em-calc($screen-sm-min)) {
        @content;
    }
}

@mixin md {
    @include after(em-calc($screen-md-min)) {
        @content;
    }
}

@mixin lg {
    @include after(em-calc($screen-lg-min)) {
        @content;
    }
}

@mixin xl {
  @include after(em-calc(1500px)) {
    @content;
  }
}

@mixin xs-only {
    @include before(em-calc($screen-xs-max)) {
        @content;
    }
}

@mixin sm-only {
    @include within (em-calc($screen-sm-min), em-calc($screen-sm-max)) {
        @content;
    }
}

@mixin md-only {
    @include within (em-calc($screen-md-min), em-calc($screen-md-max)) {
        @content;
    }
}

@mixin lg-only {
    @include grid-lg {
        @content;
    }
}

// Centered container element
@mixin container-fixed ($gutter: $grid-gutter-width)
{
    margin-right: auto;
    margin-left: auto;
    padding-left:  ($gutter / 2);
    padding-right: ($gutter / 2);
    @include clearfix;
}

// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container {
    min-width: $screen-min;
    @include container-fixed;

    @include after(em-calc($screen-sm-min)) {
        width: em-calc($container-sm);
    }

    @include after(em-calc($screen-md-min)) {
        width: em-calc($container-md);
    }

    @include after(em-calc($screen-lg-min)) {
        width: em-calc($container-lg);
    }
}

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

.container-fluid {
    min-width: $screen-min;
    @include container-fixed;
}

.container-fluid-until-md {
    min-width: $screen-min;
    @include container-fixed;

    @include after(em-calc($screen-md-min)) {
        width: em-calc($container-md);
    }

    @include after(em-calc($screen-lg-min)) {
        width: em-calc($container-lg);
    }
}

.container-fluid-until-lg {
    min-width: $screen-min;
    @include container-fixed;

    @include after(em-calc($screen-lg-min)) {
        width: em-calc($container-lg);
    }
}

// Row
//
// Rows contain and clear the floats of your columns.
@mixin make-row ($gutter: $grid-gutter-width)
{
  margin-left:  ceil(($gutter / -2));
  margin-right: floor(($gutter / -2));
  @include clearfix;
}

.row {
    @include make-row;
}

/*
 * RULES
*/