/*
* HEADINGS
*/
.title {
    font-family: $font-stack;

    // h1
    &--xxl {
        font-size: em-calc($size-xxl);
        line-height: em-calc($size-xxl + 6, $size-xxl);
        font-weight: $font-black;
    }

    &--xl {
        font-size: em-calc($size-xl);
        line-height: em-calc($size-xl + 6, $size-xl);
        font-weight: $font-black;
    }
  
    // h2
    &--l {
        font-size: em-calc($size-l);
        line-height: em-calc($size-l + 6, $size-l);
        font-weight: $font-extrabold;
    }

    // h3
    &--m {
        font-size: em-calc($size-m);
        line-height: em-calc($size-m + 6, $size-m);
        font-weight: $font-extrabold;
    }
  
    // h4
    &--s {
        font-size: em-calc($size-s);
        line-height: em-calc($size-s + 6, $size-s);
        font-weight: $font-extrabold;
    }
  
    // h5
    &--xs {
        font-size: em-calc($size-xs);
        line-height: em-calc($size-xs + 6, $size-xs);
        font-weight: $font-semibold;
    }

    &--slash {
        &:before {
            content: '/ ';
            color: $yellow;
        }
    }
}

p {
    margin-bottom: em-calc($gutter * 2);
}

.bodytext {
    @extend p;
    font-weight: $font-light;
    color: $font-color;
    font-size: em-calc(14px);
    line-height: em-calc(20px, 14px);
}

a {
    text-decoration: none;
    transition: linear 0.25s;
    color: $font-color;

    &:hover {
        text-decoration: underline;
    }
}