/*
* FONTS
*/
$font-stack		: 'Overpass', sans-serif;

$font-light		: 300;
$font-semibold	: 600; 
$font-extrabold	: 800; 
$font-black		: 900; 

/*
* COLORS
*/
$grey-dark		: #414042;
$grey-light		: #98969d;
$grey-alt		: #cbcbcb;
$yellow			: #fbad18;
$yellow-alt		: #db9715;
$white 			: #ffffff;

$font-color 	: #3b3b3b;
$heading-color 	: $grey-dark;

$border-color	: #dddddd;

$button-color	: $yellow;
$button-hover	: $yellow-alt;

/*
* HEADING SIZES
*/
$size-xxl		: 80px;
$size-xl		: 58px;
$size-l			: 34px;
$size-m			: 30px;
$size-s			: 26px;
$size-xs		: 16px;



$gutter			: $grid-gutter-width-half;

$menuMobile		: 65px;
$menuDesktop	: 95px;