.navbar {
	padding: em-calc(10px);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
    z-index: 10000;

    @include md {
    	padding: em-calc(20px);
    }

	&__content {
		background-color: $white;
		height: em-calc($menuMobile);
		
		@include md {
			height: em-calc($menuDesktop);
		}
	}
}

.navbar__content {
	position: relative;
	
	@include md {
		border: 1px solid $border-color;
	}
}

.navbar__logo {
	display: block;
	height: em-calc($menuMobile);
	padding-top: em-calc(($menuMobile - 63) / 2);
	padding-bottom: em-calc(($menuMobile - 63) / 2);
	float: left;

	@include md {
		height: em-calc($menuDesktop);
		padding-top: em-calc(($menuDesktop - 79) / 2);
		padding-bottom: em-calc(($menuDesktop - 79) / 2);
	}
}

.navbar__logo__img {
	display: inline-block;
	width: em-calc(170px);
    height: em-calc(63px);

    @include md {
		width: em-calc(215px);
		height: em-calc(79px);	
	}
}

.main__menu__toggle	{
	display: block;
    width: em-calc(60px);
    height: em-calc($menuMobile);
    position: absolute;
    right: 0;
    top: 0;
    padding-top: em-calc(($menuMobile - 45)/ 2);
    padding-bottom: em-calc(($menuMobile - 45)/ 2);
    text-align: center;
    background-repeat: no-repeat;
    background-size: 45px;
    background-position: center;
    background-image: url('../images/main/toggle-menu-open.svg');

    @include md {
    	display: none;
    }
}

.main__menu {
	display: none;
	position: absolute;
	top: em-calc($menuMobile - 1);
	left: 0;
	width: 100%;

    @include md {
    	display: block;
    	float: right;
    	height: em-calc($menuDesktop);
    	margin-right: em-calc($gutter);
    	position: static;
    	width: auto;
    }

    &__item {
    	display: block;
    	border-bottom: 1px solid $yellow-alt;

    	@include md {
    		display: inline-block;
    		border-bottom: 0;

    		&:before {
    			content: '/';
    			color: $yellow;
    		}

    		&:first-child:before {
    			content: '';
    		}

    		&:first-child a {
				margin-left: 0;
			}

			&:last-child a {
				margin-right: 0;
			}
    	}
	}

	&__item a {
		display: block;
		font-weight: $font-semibold;
		color: $white;
		position: relative;
		font-size: em-calc(16px);
	    line-height: em-calc(22px, 16px);
		padding: em-calc($gutter + 5, 16px);

		@include md {
			display: inline-block;
			font-size: em-calc(14px);
	    	line-height: em-calc(20px, 14px);
	    	font-weight: $font-light;
	    	color: $font-color;
	    	padding-top: em-calc(($menuDesktop - 20) / 2, 14px);
			padding-bottom: em-calc(($menuDesktop - 20) / 2, 14px);
			margin-left: em-calc(10px, 14px);
			margin-right: em-calc(10px, 14px);
		}

		i {
			float: right;
			line-height: 22px;

			@include md {
				display: none;
			}
		}
	}
}

.menu-toggled .main__menu {
	display: block;
	background-color: $yellow;
	min-height: 100vh;
}

.menu-toggled .main__menu__toggle {
	background-image: url('../images/main/toggle-menu-close.svg');
}