@import "../../../node_modules/swiper/dist/css/swiper.css";

@import "core";
@import "responsive";
@import "config";
@import "typography";
@import "base";
@import "project/project";
@import "pages/pages";
@import "sections/section-overview";
@import "sections/section-functions";
@import "sections/section-prices";
@import "sections/section-support";
@import "sections/section-partners";
@import "sections/section-contact";
@import "project/footer";