* {
    font-family: inherit;
    padding: 0;
    margin: 0;
    outline: none;
    border: 0;
}

body {
    margin: 0;
    font-family: $font-stack;
    font-weight: $font-light;
    -webkit-font-smoothing: antialiased;
    color: $font-color;

    @include after( 1440px ) {
        font-size: vw-calc( 16px, 1440px );
    }
}

img {
    max-width: 100%;
}

.content {
    @extend .clearfix;
}

.hero {
    padding: em-calc(80px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.flex {
    display: flex;
}

.section {
    padding-top: em-calc($gutter * 2);
    padding-bottom: em-calc($gutter * 2);

    @include md {
        padding-top: em-calc($gutter * 3);
        padding-bottom: em-calc($gutter * 3);
    }
}

/*
* BUTTONS
*/
.button {
    display: inline-block;
    background-color: $button-color;
    color: $white;
    font-size: em-calc(14px, 18px);
    line-height: em-calc(20px, 14px);
    font-weight: $font-semibold;
    border-radius: em-calc(30px, 14px);
    padding: em-calc(12px, 14px) em-calc(20px, 14px);
    transition: linear 0.25s;
}

.button:hover {
    background-color: $button-hover;
    text-decoration: none;
}

.button-black {
    @extend .button;
    background-color: $grey-dark;
}

.button-black:hover {
    background-color: lighten($grey-dark, 10);
}

/*
* INPUT
*/
input[type="text"] {
    background-color: $white;
    font-size: em-calc(14px, 18px);
    line-height: em-calc(20px, 14px);
    padding: em-calc(12px, 14px) em-calc(20px, 14px);
}